import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatusWiseAgency } from "../store/admin/action";
import male from "../assets/images/male.png";
import leftArrow from "../assets/images/leftArrow.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AgencyList = () => {
  const { agency } = useSelector((state) => state.admin);
  const history = useHistory();

  console.log("agency", agency);

  console.log("agency", agency);
  const [type, setType] = useState(2);
  const dispatch = useDispatch();
  const bdId = localStorage.getItem("bdId");

  useEffect(() => {
    dispatch(getStatusWiseAgency(bdId, type));
  }, [type]);

  const handleOpenHostHistory = (data) => {
    history.push("/bd/agencyHistory", { state: data });
  };

  return (
    <>
      <div class="page-container">
        <div class="page-content">
          <div class="main-wrapper">
            <div className="main-section p-2">
              <div className="row  mb-2 ">
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => window.history.back()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="col-5 ">
                  <p
                    className="text-dark fw-bold mb-0"
                    style={{ fontSize: "16px" }}
                  >
                    Agency List
                  </p>
                </div>
              </div>

              <div className="d-flex mt-3">
                <div className="ms-3">
                  <h4
                    className={`${type === 2
                        ? "text-dark fw-bold activeAgency"
                        : "text-gray"
                      } `}
                    style={{ fontSize: "14px" }}
                    onClick={() => setType(2)}
                  >
                    Passed
                  </h4>
                </div>
                <div className="ms-3">
                  <h4
                    className={`${type === 3
                        ? "text-dark fw-bold activeAgency"
                        : "text-gray"
                      } `}
                    style={{ fontSize: "14px" }}
                    onClick={() => setType(3)}
                  >
                    In review
                  </h4>
                </div>

              </div>

              {agency?.length > 0 ? (
                <>
                  {type === 2 && (
                    <>
                      <div className="row">
                        {agency?.length > 0 ? (
                          <>
                            {agency.slice(0, 4)?.map((data, index) => {
                              return (
                                <>
                                  <div className="agency-invitation mt-3 bg-white p-3">
                                    <div className="d-flex">
                                      <div>
                                        <img
                                          src={
                                            data?.agency?.image
                                              ? data?.agency?.image
                                              : male
                                          }
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                          alt="agency-image"
                                        />
                                      </div>
                                      <div className="ms-3">
                                        <div className="d-flex">
                                          <h6
                                            className="mb-0 fw-bold text-dark"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {data?.agency?.name}
                                          </h6>
                                          <button
                                            className="btn-primary rounded ms-2"
                                            style={{
                                              border: "none",
                                              fontSize: "9px",
                                              backgroundColor: "#0092ff",
                                            }}
                                          >
                                            Agency
                                          </button>
                                        </div>
                                        <div className="d-flex">
                                          <p className="mb-0">
                                            ID : {data?.agency?.uniqueId}
                                          </p>
                                          <p className="mb-0 ms-2">
                                            Code : {data?.agency?.agencyCode}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className=" d-flex align-items-center mt-2">
                                        <p
                                          className="mb-0 me-1 text-dark"
                                          style={{ fontSize: "11px" }}
                                        >
                                          Agency Income:
                                        </p>
                                        <img
                                          src={require("../assets/images/rcoin.png")}
                                          style={{
                                            height: "13px",
                                            width: "13px",
                                          }}
                                          alt=""
                                        />
                                        <span
                                          className="mb-0 ms-1 fw-bolder"
                                          style={{
                                            color: "#ff8300",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {data?.currentCoin
                                            ? data?.agency?.currentCoin.toFixed(
                                              2
                                            )
                                            : 0}
                                        </span>
                                      </div>
                                      <div
                                        className="d-flex align-items-center text-dark justify-content-center"
                                        style={{
                                          fontSize: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleOpenHostHistory(data?.agency)}
                                      >
                                        History
                                        <img
                                          src={leftArrow}
                                          alt=""
                                          height={10}
                                          width={10}
                                          style={{ marginLeft: "5px" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        boxShadow:
                                          "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        marginLeft: "20px"
                                      }}
                                      className="mt-3 creator"
                                    >
                                      <div className="d-flex justify-content-between mt-2 ">
                                        <h6 className="text-dark mb-1">
                                          Host Income
                                        </h6>
                                        <h6 className="text-dark mb-1">
                                          Host Count
                                        </h6>

                                      </div>

                                      <div className="d-flex justify-content-between">
                                        <p className="color-gray">
                                          <img
                                            src={require("../assets/images/rcoin.png")}
                                            style={{
                                              height: "13px",
                                              width: "13px",
                                            }}
                                            alt=""
                                          />{data?.agency?.currentHostCoin}
                                        </p>
                                        <p className="color-gray">
                                          {data?.hostCount}
                                        </p>

                                      </div>
                                      <p className="color-gray" style={{
                                        fontSize: "12px"
                                      }}>Phone  : + {data?.agency?.mobile}</p>
                                    </div>

                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center my-4">
                              <span>No data found.</span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {type === 3 && (
                    <>
                      <div className="row">
                        {agency?.map((data, index) => {
                          return (
                            <div
                              className="col-12 mt-3"
                              key={index}
                              style={{ borderBottom: "0.5px solid gray" }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      src={data?.image ? data?.image : male}
                                      style={{
                                        height: "40px",
                                        width: "40px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <p
                                      className="mb-0 fw-bold text-dark"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {data?.name}
                                    </p>
                                    <p>ID : {data?.uniqueId}</p>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="p-2 d-flex"
                                    style={{
                                      borderRadius: "30px",
                                      backgroundColor: "#ffecc9",
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-clock  p-1"
                                      style={{
                                        borderRadius: "50%",
                                        color: "#f59d00",
                                      }}
                                    ></i>

                                    <span
                                      className="ms-1"
                                      style={{ color: "#000" }}
                                    >
                                      Under review
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center my-4">
                    <span>No data found</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyList;
